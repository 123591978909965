<template>
  <div
    v-if="!isAuthenticated"
    :class="$t('footer.' + card + '.cssClass')"
    class="be-customer-card-horizonal d-flex justify-center align-center flex-column"
    :style="'background-image: url(' + $t('footer.' + card + '.imgPath') + ')'"
  >
    <h2 :class="{ 'primary--text text-uppercase': card == 'registration' }">
      {{ $t("footer." + card + ".title") }}
    </h2>
    <h4 class="text-center px-4">{{ $t("footer." + card + ".subtitle") }}</h4>
    <v-btn
      elevation="0"
      color="primary"
      class="mt-5"
      :to="{
        name: 'RegistrationUser'
      }"
      >{{ $t("footer." + card + ".button") }}</v-btn
    >
  </div>
</template>
<style scoped lang="scss">
.be-customer-card-horizonal {
  background-size: cover;
  height: 266px;
  h2 {
    color: var(--v-secondary-base);
  }
  h4 {
    color: $primary !important;
    font-weight: 400;
  }
  .v-btn {
    border-radius: $border-radius-root;
    width: 341px;
    height: 41px;
    font-size: 12px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 170px;
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "BeCustomerCardHorizontal",
  props: {
    card: { type: String, required: true }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  }
};
</script>
